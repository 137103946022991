import React from 'react';
import './Pricing.css';
import { useTranslation } from 'react-i18next';

const handleSubscribeClick = () => {
  window.location.href = 'https://fra.appsionic.com/xfidc'; 
};

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <section id="pricing" className="pricing"> 
      <h2>{t('pricing.title')}</h2>
      <div className="pricing-card">
        <h3>{t('pricing.mobileSubscription')}</h3>
        <p>{t('pricing.price')}</p>
        <p>{t('pricing.accessDescription')}</p>
        <p>{t('pricing.enterPhoneNumber')}</p>
        <p>{t('pricing.receivePin')}</p>
        <p>{t('pricing.confirmPin')}</p>
        <p>{t('pricing.agreeTerms')}</p>
        <button className="cta-btn" onClick={handleSubscribeClick}>{t('pricing.subscribeButton')}</button>
      </div>
      <div className="subscription-notice">
        <p>{t('pricing.notice')}</p>
      </div>
    </section>
  );
};

export default Pricing;
