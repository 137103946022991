import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ContactUs.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // State for success message

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Use a mock API endpoint for testing
      const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      setSuccessMessage(t('contact.successMessage')); // Set success message
  
      // Clear form fields
      setName('');
      setEmail('');
      setMessage('');
  
      // Hide the success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  

  return (
    <section id="contact-us" className="contact-us">
      <h2>{t('contact.title')}</h2>
      <p>{t('contact.subtitle')}</p>

      <form onSubmit={handleSubmit} className="contact-form">
        <input 
          type="text" 
          placeholder={t('contact.namePlaceholder')} 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          required 
        />
        <input 
          type="email" 
          placeholder={t('contact.emailPlaceholder')} 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <textarea 
          placeholder={t('contact.messagePlaceholder')} 
          value={message} 
          onChange={(e) => setMessage(e.target.value)} 
          required 
        ></textarea>
        <button type="submit" className="submit-btn">{t('contact.submitButton')}</button>
      </form>

      {/* Display success message */}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </section>
  );
};

export default ContactUs;
