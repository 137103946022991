import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import GameGallery from './components/GameGallery/GameGallery';
import VideoGallery from './components/VideoGallery/VideoGallery';
import SubscriptionBenefits from './components/SubscriptionBenefits/SubscriptionBenefits';
import Pricing from './components/Pricing/Pricing';
import Testimonials from './components/Testimonials/Testimonials';
import FAQ from './components/FAQ/FAQ';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import AboutUs from './pages/about';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import CookiePolicy from './pages/cookie-policy'; 
import UnsubscribeRefund from './pages/unsubscribe-refund'; 
import SignIn from './pages/signin'; 
import './App.css';
import './i18n'; // Import i18n configuration

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/about" element={<AboutUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} /> 
          <Route path="/unsubscribe-refund" element={<UnsubscribeRefund />} /> 
          <Route path="/signin" element={<SignIn />} /> 
          <Route path="/" element={
            <>
              <HeroSection />
              <GameGallery />
              <VideoGallery />
              <SubscriptionBenefits />
              <Pricing />
              <Testimonials />
              <FAQ />
              <ContactUs />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
