import React from 'react';
import { useTranslation } from 'react-i18next';
import './Testimonials.css';

const Testimonials = () => {
  const { t } = useTranslation();

  const testimonials = [
    {
      name: t('testimonials.james.name'),
      feedback: t('testimonials.james.feedback'),
    },
    {
      name: t('testimonials.alex.name'),
      feedback: t('testimonials.alex.feedback'),
    },
    {
      name: t('testimonials.michael.name'),
      feedback: t('testimonials.michael.feedback'),
    },
    {
      name: t('testimonials.lisa.name'),
      feedback: t('testimonials.lisa.feedback'),
    },
  ];

  return (
    <section className="testimonials">
      <h2>{t('testimonials.title')}</h2>
      <div className="testimonial-list">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <blockquote>
              <p>“{testimonial.feedback}”</p>
            </blockquote>
            <footer>- {testimonial.name}</footer>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;

/* Testimonials.css */
