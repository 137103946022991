import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AboutUs.css';

const images = [
  "/carousel/1.jpg",
  "/carousel/2.jpg",
  "/carousel/3.jpg",
  "/carousel/4.jpg",
  "/carousel/5.jpg"
];

const AboutUs = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false, 
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="about-us">
      <section className="hero-section">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Slider>
        <div className="hero-overlay">
          <h1>{t('about.heroTitle')}</h1>
          <p>{t('about.heroSubtitle')}</p>
        </div>
      </section>

      <section className="mission-section">
        <div className="container">
          <h2>{t('about.missionTitle')}</h2>
          <p>{t('about.missionDescription')}</p>
        </div>
      </section>

      <section className="values-section">
        <div className="container">
          <h2>{t('about.valuesTitle')}</h2>
          <div className="values-wrapper">
            <div className="value-item">
              <h3>{t('about.values.innovationTitle')}</h3>
              <p>{t('about.values.innovationDescription')}</p>
            </div>
            <div className="value-item">
              <h3>{t('about.values.communityTitle')}</h3>
              <p>{t('about.values.communityDescription')}</p>
            </div>
            <div className="value-item">
              <h3>{t('about.values.qualityTitle')}</h3>
              <p>{t('about.values.qualityDescription')}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="achievements-section">
        <div className="container">
          <h2>{t('about.achievementsTitle')}</h2>
          <div className="achievements-wrapper">
            <div className="achievement-item">
              <h3>{t('about.achievements.years.title')}</h3>
              <p>{t('about.achievements.years.description')}</p>
            </div>
            <div className="achievement-item">
              <h3>{t('about.achievements.users.title')}</h3>
              <p>{t('about.achievements.users.description')}</p>
            </div>
            <div className="achievement-item">
              <h3>{t('about.achievements.games.title')}</h3>
              <p>{t('about.achievements.games.description')}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
