import React from 'react';
import { useTranslation } from 'react-i18next';
import './Privacy.css';

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-page">
      <h1>{t('privacy.title')}</h1>
      <p><strong>{t('privacy.updatedDate')}</strong></p>
      <p><strong>{t('privacy.effectiveDate')}</strong></p>

      <p>{t('privacy.intro')}</p>

      <h2>{t('privacy.ownerDataController.title')}</h2>
      <p>{t('privacy.ownerDataController.description')}</p>
      <p><strong>{t('privacy.ownerDataController.companyName')}</strong></p>
      <p><strong>{t('privacy.ownerDataController.companyRegNumber')}</strong></p>
      <p><strong>{t('privacy.ownerDataController.ceo')}</strong></p>
      <p><strong>{t('privacy.ownerDataController.address')}</strong></p>
      <p><strong>{t('privacy.ownerDataController.email')}</strong></p>
      <p><strong>{t('privacy.ownerDataController.phone')}</strong></p>

      <h2>{t('privacy.infoCollection.title')}</h2>
      <h3>{t('privacy.infoCollection.whenYouProvideIt.title')}</h3>
      <p>{t('privacy.infoCollection.whenYouProvideIt.description')}</p>

      <h3>{t('privacy.infoCollection.technicalInformation.title')}</h3>
      <p>{t('privacy.infoCollection.technicalInformation.description')}</p>

      <h3>{t('privacy.infoCollection.partnersAdvertisers.title')}</h3>
      <p>{t('privacy.infoCollection.partnersAdvertisers.description')}</p>

      <h3>{t('privacy.infoCollection.cookiesOptOut.title')}</h3>
      <p>{t('privacy.infoCollection.cookiesOptOut.description')}</p>

      <h2>{t('privacy.useOfCollectedInfo.title')}</h2>
      <p>{t('privacy.useOfCollectedInfo.description')}</p>

      <h2>{t('privacy.dataRetention.title')}</h2>
      <p>{t('privacy.dataRetention.description')}</p>

      <h2>{t('privacy.dataProtection.title')}</h2>
      <p>{t('privacy.dataProtection.description')}</p>

      <h2>{t('privacy.sharingInfo.title')}</h2>
      <p>{t('privacy.sharingInfo.description')}</p>

      <h2>{t('privacy.childPrivacy.title')}</h2>
      <p>{t('privacy.childPrivacy.description')}</p>

      <h2>{t('privacy.acceptance.title')}</h2>
      <p>{t('privacy.acceptance.description')}</p>

      <h2>{t('privacy.contactUs.title')}</h2>
      <p>{t('privacy.contactUs.description')}</p>
    </div>
  );
};

export default Privacy;
