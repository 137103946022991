import React from 'react';
import { useTranslation } from 'react-i18next';
import './CookiePolicy.css';

const CookiePolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="cookie-policy-page">
      <h1>{t('cookiePolicy.title')}</h1>
      <p>{t('cookiePolicy.intro')}</p>

      <h2>{t('cookiePolicy.whatAreCookies.title')}</h2>
      <p>{t('cookiePolicy.whatAreCookies.description')}</p>

      <h2>{t('cookiePolicy.howWeUseCookies.title')}</h2>
      <p>{t('cookiePolicy.howWeUseCookies.description')}</p>
      <ul>
        <li><strong>{t('cookiePolicy.howWeUseCookies.types.essential')}</strong></li>
        <li><strong>{t('cookiePolicy.howWeUseCookies.types.performance')}</strong></li>
        <li><strong>{t('cookiePolicy.howWeUseCookies.types.advertising')}</strong></li>
        <li><strong>{t('cookiePolicy.howWeUseCookies.types.analytics')}</strong></li>
      </ul>

      <h2>{t('cookiePolicy.thirdPartyCookies.title')}</h2>
      <p>{t('cookiePolicy.thirdPartyCookies.description')}</p>

      <h2>{t('cookiePolicy.managingCookies.title')}</h2>
      <p>{t('cookiePolicy.managingCookies.description')}</p>

      <h2>{t('cookiePolicy.consentToCookies.title')}</h2>
      <p>{t('cookiePolicy.consentToCookies.description')}</p>

      <h2>{t('cookiePolicy.changesToPolicy.title')}</h2>
      <p>{t('cookiePolicy.changesToPolicy.description')}</p>

      <h2>{t('cookiePolicy.contact.title')}</h2>
      <p>{t('cookiePolicy.contact.description')}</p>
    </div>
  );
};

export default CookiePolicy;
