import React from 'react';
import './SubscriptionBenefits.css';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';

const handleSubscribeClick = () => {
  window.location.href = 'https://fra.appsionic.com/xfidc'; 
};

const SubscriptionBenefits = () => {
  const { t } = useTranslation();

  return (
    <section className="subscription-benefits">
      <h2>{t('subscriptionBenefits.title')}</h2>
      <ul>
        {Array(4).fill(null).map((_, index) => (
          <li key={index} className={`benefit-item benefit-item-${index % 2 === 0 ? 'even' : 'odd'}`}>
            <FaCheckCircle className="benefit-icon" /> {t(`subscriptionBenefits.benefit${index + 1}`)}
          </li>
        ))}
      </ul>
      <button className="cta-btn" onClick={handleSubscribeClick}>
        {t('subscriptionBenefits.subscribeButton')}
      </button>
    </section>
  );
};

export default SubscriptionBenefits;

