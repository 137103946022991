import React from 'react';
import './GameGallery.css';
import GameCard from '../GameCard/GameCard';
import { useTranslation } from 'react-i18next';

const allGameData = {
  action: [
    { title: "Jokers Whistle", image: "/action/1.jpg" },
    { title: "Impossible Survival 3D", image: "/action/2.jpg" },
    { title: "Horse Simulator Cowboy Rider", image: "/action/3.jpg" },
    { title: "Heli Air Gunship Strike War", image: "/action/4.jpg" },
    { title: "Grossy Towers Stick Challenge", image: "/action/5.jpg" },
    { title: "Goat Fight Simulator", image: "/action/6.jpg" }
  ],
  adventure: [
    { title: "Fire Flying Dragon Simulator Warrior Sky Rider 3D", image: "/adventure/1.jpg" },
    { title: "Adventure Off Flight", image: "/adventure/2.jpg" },
    { title: "2 in 1 Zombie Games", image: "/adventure/3.jpg" },
    { title: "Jail Prison Break 2018", image: "/adventure/4.jpg" },
    { title: "Swift File Transfer", image: "/adventure/5.jpg" },
    { title: "Kids IQ", image: "/adventure/6.jpg" }
  ],
  arcade: [
    { title: "Call of Sniper Duty", image: "/arcade/1.jpg" },
    { title: "Bus Physics Pro", image: "/arcade/2.jpg" },
    { title: "Bouncy Geometry", image: "/arcade/3.jpg" },
    { title: "Bird on a Wire", image: "/arcade/4.jpg" },
    { title: "Big Monkey", image: "/arcade/5.jpg" },
    { title: "Archer The Bow Master", image: "/arcade/6.jpg" }
  ],
  racing: [
    { title: "Hill Top Racing Mania", image: "/racing/1.jpg" },
    { title: "Doodle Car Racing", image: "/racing/2.jpg" },
    { title: "Car Racing Mania", image: "/racing/3.jpg" },
    { title: "Alley Biker Contest", image: "/racing/4.jpg" },
    { title: "Race Illegal High Speed 3D", image: "/racing/5.jpg" },
    { title: "Lavit Man Running", image: "/racing/6.jpg" }
  ],
  sport: [
    { title: "Ice Skater", image: "/sport/1.jpg" },
    { title: "Football Online Championship", image: "/sport/2.jpg" },
    { title: "Flick Golf", image: "/sport/3.jpg" },
    { title: "Cricket Knock Down", image: "/sport/4.jpg" },
    { title: "3 Ball Billiards", image: "/sport/5.jpg" },
    { title: "Giga Jump", image: "/sport/6.jpg" }
  ],
  kids: [
    { title: "Bistro Cook", image: "/kids/1.jpg" },
    { title: "1 2 3 for Kids", image: "/kids/2.jpg" },
    { title: "Bubble Squash", image: "/kids/3.jpg" },
    { title: "Bubble Challenge", image: "/kids/4.jpg" },
    { title: "Talking Tom", image: "/kids/5.jpg" },
    { title: "Find The 5 Differences", image: "/kids/6.jpg" }
  ],
  cooking: [
    { title: "White Chocolate Cranberry Cookies", image: "/cooking/1.jpg" },
    { title: "Vegetarian Shepard Pie", image: "/cooking/2.jpg" },
    { title: "Vegetarian Gravy", image: "/cooking/3.jpg" },
    { title: "VealShank", image: "/cooking/4.jpg" },
    { title: "Turkey Tetrazzini", image: "/cooking/5.jpg" },
    { title: "Turkey Leftovers Turkey Soup", image: "/cooking/6.jpg" }
  ],
  wallpaper: [
    { title: "Gif 9", image: "/wallpaper/1.jpg" },
    { title: "Gif 8", image: "/wallpaper/2.jpg" },
    { title: "Gif 7", image: "/wallpaper/3.jpg" },
    { title: "Gif 6", image: "/wallpaper/4.jpg" },
    { title: "Gif 5", image: "/wallpaper/5.jpg" },
    { title: "Gif 4", image: "/wallpaper/6.jpg" }
  ]
};

const getRandomGames = (data, count) => {
  const allGames = Object.values(data).flat();
  const shuffled = allGames.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const GameGallery = () => {
  const { t } = useTranslation();
  const randomGames = getRandomGames(allGameData, 12);

  return (
    <section id="games" className="game-gallery"> 
      <h2>{t('gameGallery.title')}</h2>
      <div className="gallery-grid">
        {randomGames.map((game, index) => (
          <div key={index} className="gallery-item">
            <GameCard 
              title={game.title} 
              image={game.image}
              type="game"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default GameGallery;


