import React from 'react';
import Slider from 'react-slick';
import './HeroSection.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const images = [
  "/carousel/1.jpg",
  "/carousel/2.jpg",
  "/carousel/3.jpg",
  "/carousel/4.jpg",
  "/carousel/5.jpg"
];

const handleSubscribeClick = () => {
  window.location.href = 'https://fra.appsionic.com/xfidc';
};

const HeroSection = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: 'linear'
  };

  return (
    <section className="hero-section">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-image">
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
      <div className="hero-overlay">
        <h1 className="hero-title">{t('heroSection.title', { brand: 'Appsionic' })}</h1>
        <p className="hero-description">{t('heroSection.description')}</p>
        <button className="cta-btn" onClick={handleSubscribeClick}>
          {t('heroSection.subscribeButton')}
        </button>
        <p className="pricing-info">{t('heroSection.pricingInfo')}</p>
      </div>
    </section>
  );
};

export default HeroSection;


