import React from 'react';
import { useTranslation } from 'react-i18next';
import './UnsubscribeRefund.css';

const UnsubscribeRefund = () => {
  const { t } = useTranslation();

  return (
    <div className="unsubscribe-refund-page">
      <h1>{t('unsubscribe.title')}</h1>
      <p>{t('unsubscribe.intro')}</p>

      <h2>{t('unsubscribe.unsubscribeTitle')}</h2>
      <p>{t('unsubscribe.unsubscribeInstructions')}</p>
      <ul>
        <li><strong>{t('unsubscribe.smsMethod')}</strong> {t('unsubscribe.smsInstructions')}</li>
        <li><strong>{t('unsubscribe.callMethod')}</strong> {t('unsubscribe.callInstructions')}</li>
        <li><strong>{t('unsubscribe.emailMethod')}</strong> {t('unsubscribe.emailInstructions')}</li>
      </ul>

      <h2>{t('unsubscribe.refundTitle')}</h2>
      <p>{t('unsubscribe.refundPolicy')}</p>
      <p>{t('unsubscribe.refundInstructions')}</p>
      <ul>
        <li>{t('unsubscribe.refundEmail')}</li>
        <li>{t('unsubscribe.refundDetails')}</li>
      </ul>
      <p>{t('unsubscribe.refundAcknowledgment')}</p>

      <h2>{t('unsubscribe.faqTitle')}</h2>
      <div className="faq-section">
        <h3>{t('unsubscribe.faqWhatIs')}</h3>
        <p>{t('unsubscribe.faqWhatIsAnswer')}</p>

        <h3>{t('unsubscribe.faqHowSubscribed')}</h3>
        <p>{t('unsubscribe.faqHowSubscribedAnswer')}</p>

        <h3>{t('unsubscribe.faqCharge')}</h3>
        <p>{t('unsubscribe.faqChargeAnswer')}</p>

        <h3>{t('unsubscribe.faqPlatforms')}</h3>
        <p>{t('unsubscribe.faqPlatformsAnswer')}</p>

        <h3>{t('unsubscribe.faqContactSupport')}</h3>
        <p>{t('unsubscribe.faqContactSupportAnswer')}</p>
      </div>
    </div>
  );
};

export default UnsubscribeRefund;
