import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GameCard.css';
import { useTranslation } from 'react-i18next';

const GameCard = ({ title, image, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleActionClick = () => {
    console.log(`Clicked on: ${title}`);
    navigate('/signin'); // Redirects to sign-in page
  };

  const displayTitle = title.length > 10 ? `${title.slice(0, 10)}...` : title;

  return (
    <div className="game-card" style={{ backgroundImage: `url(${image})` }}>
      <div className="game-card-overlay">
        <h3 className="game-card-title" title={title}>{displayTitle}</h3>
        <button className="game-card-btn" onClick={handleActionClick}>
          {t('gameCard.download')}
        </button>
      </div>
    </div>
  );
};

export default GameCard;


